import React, { Fragment, useState } from 'react';
import { login } from '../services/authService';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import logo from './img/logo.png';
import LoginAnimation from './loginAnimation/loginAnimation';



const LoginForm = (props) => {

  
  const navigate = useNavigate();
  console.log("hi hshshs me and login to APP")

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPC = useMediaQuery({query: '(min-device-width: 1224px)'})


  const [messageApi, contextHolder] = message.useMessage();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(formData, handleRedirect); // Call the login function from authService.js
      console.log('Login Successful: ', response.data.data.login);
      if (response.data.data.login) {
        //props.onLogin(true);
        messageApi.open({
          type: 'success',
          content: 'login Success',
        });
      }else{
        console.log('Login cj: ', response.data.data.login);
        messageApi.open({
          type: 'error',
          content: 'Login Faill please check credentials',
        });
      }
      // Redirect or perform any other action upon successful login
    } catch (error) {
      console.error('Login Error:', error);
      // Handle login error, e.g., display an error message to the user
    }
  };


  const handleRedirect = (route) => {
    navigate(route);
  };


  return (
    <Fragment>
      {contextHolder}
      <section className={`login-form  ${isTabletOrMobile?'':'flexRow full-height'} ${!isPC?'mobileContent':''}`}>
      <div className='container'>

      {isPC && <div className="image-section">
        <div className="image-wrapper">
            <LoginAnimation/>    
        </div>

        {/* <div className="content-container">
          <h1 className="section-heading">Success begins with <span>Education.</span></h1>
          <p className="section-paragraph">Education is the key that unlocks the limitless doors of knowledge, empowering us to shape our destinies and create a brighter future.</p>
        </div> */}
      </div>}

      <div className="form-section">
        <div className="form-wrapper">
          <form onSubmit={handleSubmit}>

            <div >
              <img src={logo} alt='SolidMotion' style={{width:'75%',marginBottom:'30px'}}></img>
            </div>
            {/* <p>Enter your credentials to access your account.</p> */}

            <div className="input-container">
              <div className="form-group">
                <label for="email">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label for="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="remember-forgot">
              <div className="remember-me">
                <input type="checkbox" value="remember-me" id="remember-me" />
                <label for="remember-me">Remember me</label>
              </div>

              <a href="#">Forgot password?</a>
            </div>

            <button className="login-btn" type='submit'>Log In</button>

            <div className="or-divider">or</div>
            </form>
            <button className="google-signin">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="96px" height="96px"><path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg>
              <span>Sign in with Google</span>
            </button>
          
        </div>
      </div>
      </div>
    </section>
    </Fragment>

  );

};

export default LoginForm;




