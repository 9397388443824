import React from "react";
import { Fragment } from "react";
import { useParams } from 'react-router-dom';
import EmbendSingalImageViewer from "./pannellum/EmbendSingalImageViewer";

const EmbendLinkCreator =()=>{
    const { apiKey,scene } = useParams();
    console.log('username ',apiKey,' :scene ',scene);
    return(
        <Fragment>
            <EmbendSingalImageViewer 
                image={'https://pannellum.org/images/alma.jpg'}
                imageFromServer= {true}
                default={{
                    hotSpotDebug:false
                }}
            />
        </Fragment>
    )
}


export default EmbendLinkCreator;