import React, { useState ,useEffect} from 'react';
import { Form, Input, InputNumber, Button, Upload, Tabs, Switch, Row,Col,Tag,Select ,Card,Space,Divider } from 'antd';
import { UploadOutlined ,CloseOutlined} from '@ant-design/icons';
import {multiImageViewerDataSend, multiImageViewerDataSendPygen} from '../services/dataSendGet';

import SinglePannellumViewer from '../component/pannellum/SinglePannellumViewer';

const { TabPane } = Tabs;
const { Option } = Select;

const AddMulti360Form = ({ record,uploadState}) => {

  const [mouseMoveCoordinates, setMouseMoveCoordinates] = useState({ pitch: null, yaw: null,hfov:null,NorthOffset:null });
  const [imageChange ,setimageChange]= useState([]);
  const [fileList, setFileList] = useState([]);
  const [tabImages, setTabImages] = useState({});
  const [tabErrors, setTabErrors] = useState({});
  const [firstSceneOptions, setFirstSceneOptions] = useState([]);
  const [isMultiRes,setMultires] = useState(false);
  const [uniqueFolderName, setUniqueFolderName] = useState([]);

  

  const [form] = Form.useForm();
  const [tabs, setTabs] = useState([
    { key: '1', label: 'Image 1' },
  ]);



  const addTab = () => {
    const newKey = (tabs.length + 1).toString();
    setTabs([...tabs, { key: newKey, label: `Image ${newKey}` }]);
  };

  const removeTab = (key) => {
    setTabs(tabs.filter((tab) => tab.key !== key));
   
  };

  // const onFinish = (values) => {
  //   const { data, default: defaultValues, ...otherValues } = values;
  //   const modifiedDefaultValues = {
  //     ...defaultValues,
  //     author:defaultValues.author=== undefined ? ' ' : defaultValues.author,
  //     autoLoad: defaultValues.autoLoad === undefined ? true : defaultValues.autoLoad, 
  //     autoRotate: defaultValues.autoRotate === undefined ? false : defaultValues.autoRotate,
  //     sceneFadeDuration : defaultValues.sceneFadeDuration === undefined ? 1000 : defaultValues.sceneFadeDuration
  //   };

  //   const modifiedValues = {
  //     data,
  //     default: modifiedDefaultValues,
  //   };
  //   console.log('Modified Data:', modifiedValues);
  //   multiImageViewerDataSend(modifiedValues);
  // };

  const onFinish = (values) => {
    // Log the data for debugging purposes
    console.log('Record from listing-page:', record);
    console.log('finish value = ', values.data);
  
    // Create a new FormData object
    const formData = new FormData();
  
    // Iterate over the 'data' object
    for (const key in values.data) {
      if (values.data.hasOwnProperty(key)) {
        const dataEntry = values.data[key];
        const dataFieldName = `data[${key}]`;
  
        // Append each property of the data entry as a separate field
        for (const dataKey in dataEntry) {
          if (dataEntry.hasOwnProperty(dataKey)) {
            // Check if the property is an array
            if (Array.isArray(dataEntry[dataKey])) {
              // If it's an array, iterate through its items and append them
              dataEntry[dataKey].forEach((value, index) => {
                //formData.append(`${dataFieldName}[${dataKey}][${index}]`, value);
                formData.append(`${dataFieldName}[${dataKey}][${index}]`, JSON.stringify(value));
              });
            } else if (typeof dataEntry[dataKey] === 'object') {
              // If it's an object, iterate through its properties and append them
              for (const objKey in dataEntry[dataKey]) {
                if (dataEntry[dataKey].hasOwnProperty(objKey)) {
                  formData.append(`${dataFieldName}[${dataKey}][${objKey}]`, dataEntry[dataKey][objKey]);
                }
              }
            } else {
              // For regular properties, just append them
              //formData.append(`${dataFieldName}[${dataKey}]`, dataEntry[dataKey]);
              if (dataKey === 'compass') {
                formData.append(`${dataFieldName}[${dataKey}]`, dataEntry[dataKey] !== undefined ?  dataEntry[dataKey]: false);
              } else {
                formData.append(`${dataFieldName}[${dataKey}]`,  dataEntry[dataKey] );
              }
            }
          }
        }


  
        if (dataEntry.image && !values.multiRes) {
            formData.append(`${dataFieldName}[image]`, dataEntry.image[0].originFileObj.uid);
            console.log('Appended image:', `${dataFieldName}[image]`, dataEntry.image[0].originFileObj.uid);
        }
      }  
    }
  

    if(values.multiRes){
      formData.append('multiRes',values.multiRes);
    }

    // Handle the 'default' object
    const defaultValues = values.default;
    const modifiedDefaultValues = {
      ...defaultValues,
      author: defaultValues.author === undefined ? ' ' : defaultValues.author,
      autoLoad: defaultValues.autoLoad === undefined ? true : defaultValues.autoLoad,
      autoRotate: defaultValues.autoRotate === undefined ? false : defaultValues.autoRotate,
      sceneFadeDuration: defaultValues.sceneFadeDuration === undefined ? 1000 : defaultValues.sceneFadeDuration,
      addCompanyID:record.key,
      pyGen :values.multiRes,
      folderUniqe: uniqueFolderName,
      companyName:record.companyName
      // Add more modifications as needed
    };
  
    // Append modified 'default' values to the FormData object
    for (const key in modifiedDefaultValues) {
      if (modifiedDefaultValues.hasOwnProperty(key)) {
        formData.append(`default[${key}]`, JSON.stringify(modifiedDefaultValues[key]));
      }
    }

    console.log('modifiedDefaultValues = ',JSON.stringify(modifiedDefaultValues));

    if(!values.multiRes){
      for (const key in values.data) {
        console.log(`images[${key}]`, values.data[key].image[0].originFileObj);
        //formData.append('image',values.data[key].image[0].originFileObj);
        formData.append(`images`, values.data[key].image[0].originFileObj);
      }
    }

  
    // Log the entire FormData object for debugging
    console.log('formData:', formData);
  
    if(values.multiRes){
      multiImageViewerDataSendPygen(formData)
      .then(response => {
        console.log(response.data);
        uploadState(true,record,'multi')
        form.resetFields();
      }).catch(error => {
        console.error(error);
      });
    }else{
      multiImageViewerDataSend(formData)
      .then(response => {
        console.log(response.data);
        uploadState(true,record,'multi')
        form.resetFields();
      }).catch(error => {
        console.error(error);
      });
    }
    // Call your API function with the 'formData'
  


  };
  

  
  



  




  const handleMouseMove = (pitch, yaw, hfov,NorthOffset) => {
    setMouseMoveCoordinates({ pitch, yaw, hfov,NorthOffset });
  };

  const onValuesChange = (_, allValues) => {
    const newTabErrors = {};
    const newFirstSceneOptions = [];
 //console.log('error ',newTabxErrors);
    // Iterate through all tabs
    if (allValues.data) {
    Object.values(allValues.data).forEach((tab) => {
      const tabKey = tab.key;
      const tabValues = allValues.data[tabKey];

      // Check if tab has validation errors
      if (tabValues) {
        const errors = form.getFieldError(['data', tabKey]);
        newTabErrors[tabKey] = errors && errors.length > 0;
      }
      //console.log("tab  = ",tab);
      if (tab ) {
        //tab.forEach((tabData) => {
          console.log("tabData",tab);
          // if (tab && tab.sceneName && tabValues.sceneName) {
            console.log('tabData.sceneName) ',tab.sceneName);
            newFirstSceneOptions.push(tab.sceneName);
          //}
        //});
      }

    });
  }

    console.log('firstSceneOptions', newFirstSceneOptions); 
    setTabErrors(newTabErrors);
    setFirstSceneOptions([...new Set(newFirstSceneOptions)]);
  };


  const customRequest=({ file, onSuccess, onError })=>{
    setFileList(file);
    try {
      // Simulate a successful upload
      setTimeout(() => {
        // Modify the status and response properties of the uploaded file
        file.status = 'done';
        file.response = 'success';
  
        onSuccess();
  
      }, 2000); 
    } catch (error) {
      console.error('File upload failed:', error);
      onError();
    }
}
  const props = (tabKey)=>({
    customRequest,
    maxCount:1,
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        console.log(file, fileList);
        const localImageUrl = URL.createObjectURL(file.originFileObj);
        
        setTabImages({
          ...tabImages,
          [tabKey]: localImageUrl,
        });  
        setimageChange(localImageUrl)
      }
      setFileList([...fileList]);
      console.log('iamge cj = ',tabImages);
    },
    
  });

 const handelTabChange=(key)=>{
  console.log(' hi sapu ',key);
  console.log('iamge cj = ',tabImages);
  console.log('tab iamge cj = ',tabImages[key]);

  //generateUniqueFolderName(parseInt(key, 10));
 }
 
 const onEdit = (targetKey, action) => {
  if (action === 'add') {
    addTab();
  } else {
    removeTab(targetKey);
  }
};

const changeMultiresMode =()=>{
  setMultires(!isMultiRes);
}

// const generateUniqueFolderName = (tabKey) => {
//   const generatedName = `folder_${Date.now().toString(36)}_${Math.random().toString(36).substr(2, 5)}`;
  
//   setUniqueFolderName((prevNames) => {
//     const updatedNames = [...prevNames];
//     if (typeof updatedNames[tabKey] === 'undefined'|| updatedNames[tabKey] === null) {
//       updatedNames[tabKey] = generatedName;
//     }
//     return updatedNames;
//   });
//   console.log(`Generated Folder Name for Tab ${tabKey}:`, generatedName);
// };

//genarate singale folder name
const generateUniqueFolderName = (tabKey) => {
  const generatedName = `folder_${Date.now().toString(36)}_${Math.random().toString(36).substr(2, 5)}`;
  
  return generatedName;
  // Now you can use the generated name as needed.
  //console.log(`Generated Folder Name for Tab ${tabKey}:`, generatedName);
};

useEffect(() => {
  // Generate unique folder name for the first tab when the component mounts
  setUniqueFolderName(generateUniqueFolderName(1));
}, []);

  return (
    <Form form={form} name="dynamic-tab-form" onFinish={onFinish} initialValues={{ tabs }} onValuesChange={onValuesChange}  encType="multipart/form-data">

      <Form.Item
             name={'multiRes'}
             label="Multiresolution panorama"
            >
              <Switch checked={isMultiRes}  onChange={changeMultiresMode}/>
      </Form.Item>        
   
      <Tabs
        type="editable-card"
        // tabBarExtraContent={<Button  className='addBtn' onClick={addTab}>Add Tab</Button>}
        onChange={(key) => handelTabChange(key)}
        onEdit={onEdit}
      >
        {tabs.map((tab) => (
           
          <TabPane tab={(
            <div>
              {tab.label}
              {tabErrors[tab.key] && <span className="error-indicator">*</span>}
            </div>
          )}  key={tab.key}  >

            {/* {!isMultiRes ? ( */}
              <div>
            {(imageChange.length !== 0 && !isMultiRes)  && (tabImages[tab.key]!== undefined) && ( <div>
              <SinglePannellumViewer image={tabImages[tab.key]} imageFromServer={false} onCenterCoordinates={handleMouseMove}/>
              <div className='flex-group justfy-space-between statct'>
                <p>Pitch: <Tag color="purple">{mouseMoveCoordinates.pitch}</Tag> </p>
                
        
                <p>Yaw: <Tag color="blue">{mouseMoveCoordinates.yaw}</Tag></p>
                <p>hfov: <Tag color="geekblue">{mouseMoveCoordinates.hfov}</Tag></p>
                <p>NorthOffset: <Tag color="cyan">{mouseMoveCoordinates.NorthOffset}</Tag></p>
              </div>
              </div>)}
              
              {!isMultiRes ? (<Form.Item
                name={['data', tab.key, 'image']}
                label="Image"
                valuePropName="fileList"
                getValueFromEvent={(e) => e && e.fileList}
                rules={[{ required: true, message: 'Image is required' }]}
              >
                <Upload {...props(tab.key)}  listType="picture">
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
              </Form.Item>):(
                <>
                <Row>
                  <Col span={12}>
                  <Form.Item
                  name={['data', tab.key, 'ufolder']}
                  label="Folder"
                  initialValue={uniqueFolderName || ''}
                  >
                      <Input readOnly />
                  </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  
                  <Col span={8}>
                  <Form.Item
                     name={['data', tab.key, 'path']}
                     label="Path"
                     >
                      <Input />
                     </Form.Item>
                  </Col>
                  <Col span={8}>
                  <Form.Item
                     name={['data', tab.key, 'fallbackPath']}
                     label="FallbackPath"
                     >
                      <Input />
                     </Form.Item>
                  </Col>
                  <Col span={8}>
                  <Form.Item
                     name={['data', tab.key, 'extension']}
                     label="Extension"
                     >
                      <Input />
                     </Form.Item>
                  </Col>

                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item
                      name={['data', tab.key, 'tileResolution']}
                      label="Tile Resolution"
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                      name={['data', tab.key, 'maxLevel']}
                      label="MaxLevel"
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                    <Form.Item
                      name={['data', tab.key, 'cubeResolution']}
                      label="CubeResolution"
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>
                </Row>
                </>
              )}

              <Row  gutter={5}>
                <Col span={12}>
                  <Form.Item
                    name={['data', tab.key, 'title']}
                    label="title"
                    rules={[{ required: true, message: 'Name is required' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={['data', tab.key, 'sceneName']}
                    label="Scene Name"
                    rules={[{ required: true, message: 'Scene Name is required' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
  
              </Row>
              <Row>
                <Col span={6}>
                    <Form.Item
                  name={['data', tab.key, 'hfov']}
                  label="hfov"
                  rules={[{ required: true, message: 'hfov is required' }]}
                  >
                    <InputNumber/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                  name={['data', tab.key, 'pitch']}
                  label="pitch"
                  rules={[{ required: true, message: 'pitch is required' }]}
                  >
                    <InputNumber/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                              
                  <Form.Item
                  name={['data', tab.key, 'yaw']}
                  label="yaw"
                  rules={[{ required: true, message: 'yaw is required' }]}
                  >
                    <InputNumber/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                  name={['data', tab.key, 'northOffset']}
                  label="northOffset"
                  rules={[{ required: true, message: 'northOffset is required' }]}
                  >
                    <InputNumber/>
                  </Form.Item>
                </Col>
              </Row>
              
              <Row>
                  <Col>
                    <Form.Item
                    name={['data', tab.key, 'compass']}
                    label="compass"
                    >
                      <Switch  />
                    </Form.Item>
                  </Col>
              </Row>
              
         
  
              {/* <Button onClick={() => removeTab(tab.key)}>Remove Tab</Button> */}
  
  
                {/* Nest Form.List */}
                <Card title="HotSpots for Scene">
                <Form.Item label="List">
                    <Form.List
                     name={['data', tab.key, 'hotSpots']}
                    >
                      {(subFields, subOpt) => (
                        <div>
                          <div style={{ display: 'flex', flexDirection: 'column',justifyContent: 'center', rowGap: 16 ,marginBottom:'15px'}}>
                          {subFields.map((subField,index) => {
                               const isInitialScene = form.getFieldValue(['data', tab.key, 'hotSpots', subField.name, 'type']) === 'scene' && index === 0;
                          return (
  
                            <Space key={subField.key}>
  
                              <Form.Item noStyle name={[subField.name, 'pitch']}>
                                <InputNumber placeholder="pitch" />
                              </Form.Item>
  
                              <Form.Item noStyle name={[subField.name, 'yaw']}>
                                <InputNumber placeholder="yaw" />
                              </Form.Item>
  
                              <Form.Item noStyle name={[subField.name, 'text']}>
                                <Input placeholder="text" />
                              </Form.Item>
  
                                <Form.Item
                                noStyle
                                name={[subField.name, 'type']}
                                initialValue="info"
                              >
                                <Select style={{ width: '150px'}}>
                                  <Option value="scene">Scene</Option>
                                  <Option value="info">Info</Option>
                                </Select>
                              </Form.Item>
  
                              <Form.Item
                                  
                                  noStyle
                                  name={[subField.name, 'sceneLink']}
                                  label="Scene Link"
                                  hidden={!isInitialScene}
                                >
                                  <Input  placeholder="scene Link"  />
                              </Form.Item>
  
                              <Form.Item
                                  
                                  noStyle
                                  name={[subField.name, 'URL']}
                                  label="URL"
                                  hidden={isInitialScene}
                                >
                                  <Input  placeholder="URL"  />
                              </Form.Item>
                              
                              <CloseOutlined
                                onClick={() => {
                                  subOpt.remove(subField.name);
                                }}
                              />
                          
                            </Space>
                          )})}
                          </div>
                          <Button type="dashed" onClick={() => subOpt.add()} block>
                            + Add 
                          </Button>
                        </div>
                      )}
                    </Form.List>
                    </Form.Item>
                </Card>             
                </div>

          </TabPane>
        ))}
      </Tabs>
      <Divider />
      <Row  gutter={5}>
                  <Col span={12}>
                    <Form.Item
                      name={['default', 'firstScene']}
                      label="First Scene"
                      rules={[{ required: true, message: 'First Scene is required' }]}
                    >
                      {/* <Input placeholder="First Scene" /> */}
                      <Select placeholder="Select First Scene">
                      {firstSceneOptions.map((sceneName) => (
                        <Option key={sceneName} value={sceneName}>
                          {sceneName}
                        </Option>
                      ))}
                    </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={['default', 'author']}
                      label="Author"
                    >
                      <Input placeholder="Author" />
                    </Form.Item>
                  </Col>
                  
    </Row>
    <Row  gutter={5}>
            <Col span={8}>
                    <Form.Item
                      name={['default', 'sceneFadeDuration']}
                      label="Scene Fade Duration"
                    >
                      <InputNumber defaultValue={1000} />
                    </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item
             name={['default', 'autoLoad']}
             label="Auto Load"
            >
              <Switch defaultChecked />
              </Form.Item>
            </Col>
            <Col span={8}>
            <Form.Item
             name={['default', 'autoRotate']}
             label="Auto Rotate"
            >
              <Switch  defaultChecked={false}/>
              </Form.Item>
            </Col>
    </Row>
    <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddMulti360Form;
