import React, { useEffect, useRef, useState } from 'react';
import './pannellum.css'
import pannellum from './pannellum';
import {getImagefromServer} from '../../services/dataSendGet'

const EmbendSingalImageViewer = (props) => {
  const panoramaRef = useRef(null);

const [panoramaViewer, setPanoramaViewer] = useState(null);
  
  useEffect(() => {
    const initializeViewer = async () => {
      if (panoramaViewer) {
        panoramaViewer.destroy();
      }

    if (pannellum && panoramaRef.current) {
      try {
        // Call the ImagetoView function using await
        let imageUrlObject='';
        if(props.imageFromServer){
           imageUrlObject = await ImagetoView(props.image.imagePath);
        }else{
          imageUrlObject = props.image;
        }


    const hotSpotDebug = props.default && props.default.hotSpotDebug !== undefined ? props.default.hotSpotDebug : true;

    const viewer = window.pannellum.viewer(panoramaRef.current, {
            // Your Pannellum configuration here
            "default": {
                "firstScene": "library",
                "author": "Matthew Petroff",
                "sceneFadeDuration": 1000,
                "autoLoad": true,
                "hotSpotDebug":hotSpotDebug,
                'showZoomCtrl':true 
            },
            
            "scenes": {
                "library": {
                    "title": "chathura Jayaranga",
                    "hfov": 100,
                    "pitch": 10,
                    "yaw": 50,
                    "compass": true,
                    "northOffset": 289,
                    "type": "equirectangular",
                    "panorama": imageUrlObject,
                    "hotSpots": [
                        {
                            "pitch": 60,
                            "yaw": 60,
                            "type": "info",
                            "text": "The skylight is supported by an iron truss and has a second, peaked skylight over it."
                        },
                        {
                            "pitch": 10,
                            "yaw": 25,
                            "type": "info",
                            "text": "Much of the library was constructed from cast-iron to reduce the risk of fire."
                        },
                        {
                            "pitch": 0,
                            "yaw": 8,
                            "type": "info",
                            "text": "The lighting was originally gas."
                        },
                        {
                            "pitch": -3,
                            "yaw": 115,
                            "type": "info",
                            "text": "The JHU library catalog contains the Peabody Library’s holdings.",
                            "URL": "https://catalog.library.jhu.edu/"
                        }
                    ]
                },
            }
          });


          const getCenterPitchAndYaw = () => {
            if (viewer) {
              const centerPitch = viewer.getPitch();
              const centerYaw = viewer.getYaw();
              return { centerPitch, centerYaw };
            }
            return { centerPitch: null, centerYaw: null };
          };
    
          // Add a mousemove event listener to the panorama viewer's container
          panoramaRef.current.addEventListener('mousemove', (event) => {
            const { centerPitch, centerYaw } = getCenterPitchAndYaw();
            console.log('my cood Pitch = ',centerPitch,'Yaw = ',centerYaw)
            if (props.onCenterCoordinates) {
              props.onCenterCoordinates(centerPitch, centerYaw);
            }
          });

          setPanoramaViewer(viewer);
        } catch (error) {
          console.error('Error initializing viewer:', error);
        }
    } 
  };

  initializeViewer();

  }, []);

  const ImagetoView = async (imagePath) => {
    try {
      const response = await getImagefromServer(imagePath);
      if (response) {
       console.log('res cj = ',response)
        
        return response;
      } else {
        throw new Error('Failed to fetch image');
      }
    } catch (error) {
      console.error('Error fetching image:', error);
      return null; // Return null in case of an error
    }
  };
  

  return (
    <div>
        {!props.imageFromServer && props.image.basePath}
        <div ref={panoramaRef} style={{ width: '100vw', height: '100vh' }} />
    </div>
  );
};

export default EmbendSingalImageViewer;
