import React, { Fragment } from 'react';
import { Form, Input } from 'antd';

const AddCompanyForm = ({ form, formData, onInputChange }) => {
  return (
    <Fragment>
      <Form
        form={form}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="vertical"
        initialValues={{
          size: 'default',
        }}
        size={'default'}
        style={{ maxWidth: 600 }}
      >
        <Form.Item
          label="Company Name"
          name="companyName"
          rules={[
            {
              required: true,
              message: 'Please enter the company name',
            },
          ]}
        >
          <Input name="companyName" value={formData.companyName} onChange={onInputChange} />
        </Form.Item>
        <Form.Item 
        label="Domain Name"
        name="domainName"
        rules={[
            {
              required: true,
              message: 'Please enter the Domain name',
            },
            {
                pattern: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Please enter a valid domain name',
              },
          ]}
        >
          <Input name="domainName" value={formData.domainName} onChange={onInputChange} />
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default AddCompanyForm;