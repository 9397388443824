import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const sessionData = JSON.parse(localStorage.getItem('sessionData'));

    if (sessionData) {
      const { expirationTime } = sessionData;
      const currentTime = new Date().getTime();

      if (currentTime > expirationTime) {
        // Session has expired on the client side
        localStorage.removeItem('sessionData'); // Clear the expired session data
        navigate('/login'); // Redirect to the login page
      }
    } else {
      // Session data is not found, redirect to the login page
      navigate('/login');
    }
  }, [navigate]);

  return children;
};

export default PrivateRoute;
