import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import ApiEndPoint from '../config'; 

export const login = async (formData,onLoginSuccess) => {

 // const ApiEndPoint = 'http://localhost:3000';
  //https://api.kashikoi.lk/

 console.log(formData.username);
  try {
    const config = {
      headers: {
        'X-API-Key': 'cjsMks',
        'Content-Type': 'application/json',
      },
      withCredentials: false,
    };

    const response = await axios.post(ApiEndPoint+'/login', {
      username:formData.username,
      password:formData.password,
    }, config);

    // Check the response status code (assuming success is HTTP status code 200)
    if (response.status === 200) {
      console.log(JSON.stringify(response.data));
      // Replace this with your actual response parsing logic
      //const responseData = response.data;

      if(response.data.data.data.login){
        const sessionData = {
          token: response.data.data.data.token,
          expirationTime: new Date().getTime() + 7200000, // Set the expiration time (e.g., 2 hours from now)
        };
        
        localStorage.setItem('sessionData', JSON.stringify(sessionData));
        onLoginSuccess('/');
      }


      return response.data;
    } else {
      throw new Error('Login failed'); // Handle login errors
    }
  } catch (error) {
    console.error('Error:', error);
    throw error; // Handle login errors
  }
};




export const useLogout = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('sessionData');
    navigate('/login');
  };

  return logout;
};
