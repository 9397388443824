import React,{useState,useEffect} from 'react';
import { Button ,Table, Modal, Form, notification, Dropdown, Tag, Badge} from 'antd';
import { PlusOutlined,DeleteFilled,TagFilled,TagsFilled,CodeSandboxOutlined} from '@ant-design/icons';
import AddCompanyForm from '../component/AddCompanyForm'
import {saveCompany,getAll360Data, getInnerTableData,delete360fromList,ApiEndPoint} from '../services/dataSendGet'
import { useNavigate  } from 'react-router-dom';
import Add360Form from '../component/Add360Form';
import AddMulti360Form  from '../component/AddMulti360Form';
import View360FromDb from '../component/View360FromDb';

import './listing-pageStyles.css';


//import {imageUpload} from '../services/dataSendGet'


const ListingPage =()=>{

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAddOpen, setIsModalAddOpen] = useState(false);
    const[isModalAddMultiOpen,setIsModalAddMultiOpen]= useState(false);
    

      const [formData, setFormData] = useState({});
      const [data, setData] = useState([]);
      const [form] = Form.useForm();
      const [record, setRecord] = useState('');
      const [innerRecord, setInnerRecord] = useState('');

      const [innerTableData, setInnerTableData] = useState([]);
      const [loading, setLoading] = useState(false);
      const [expandedRowKey, setExpandedRowKey] = useState(null);

      const [SingleCount,setSingleCount] = useState(0)
      const [MultiCount,setMultiCount] = useState(0)

      const[view360fromDB,setView360fromDB] = useState(false);
     

      const [modal, contextHolderconfirm] = Modal.useModal();
   
    

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      

      const [api, contextHolder] = notification.useNotification();
      
      const openNotificationWithIcon = (type) => {
        api[type]({
          message: 'New 360 added Successfully',
          description:
            'Added Successfully.',
        });
      };

    
      const items = (record) => [
        {
          key: '1',
          label: (
            <span onClick={() => handleSingale360(record)}>
              singale image 360
            </span>
          ),
        },
        {
          key: '2',
          label: (
            <span onClick={() => handleMulti360(record)}>
              multi image 360
            </span>
          ),
        },
      ];
   

    const columns = [
        {
            title: "Company Name",
            dataIndex: "companyName",
            render: (text) => React.createElement("a", null, text)
        },
        {
            title: "API Key",
            className: "column-money",
            dataIndex: "APIKey",
            align: "right"
        },
        {
            title: "Domain Name",
            dataIndex: "domainName"
        },
        {
          title: "Count",
          dataIndex: "Count",
          render: (text, record) => (
        
            <div>
              {record.SingleCount!==0 && <p> <Badge status="success" text="Single " />,: {record.SingleCount}</p>}
              { record.MultiCount!==0  && <p> <Badge status="processing" text="Multi" /> : {record.MultiCount}</p>}
            </div>
          ),
        },
        {
            title: 'Action', 
            dataIndex: 'action',
            render: (_, record) => (
              <Dropdown menu={{ items: items(record) }}  type="primary" placement="bottomLeft" arrow>
                <Button>Add</Button>
              </Dropdown>
            ),
          },
    ];


    const configConfirm = {
      title: 'Delete',
      content: (
        <>
          <h4>Are you sure you want to delete this</h4>
        </>
      ),
    };
    

    const innerColumns = [
      {
        title: 'Scene Name',
        dataIndex: 'sceneName',
        render:(_,record) => (
          <span >
              <div className='flex-group'>{record.sceneName} {record.SingleMulti === 'Single' ?<TagFilled />: <div ><TagsFilled /> <Tag color="#87d068"> {record.pythonGen === 1 && <CodeSandboxOutlined />}  Multi-image</Tag> </div> }</div> 
          </span>
        ),
      },
      {
        title: 'Embend URL',
        dataIndex: 'imageName',
        render:(_,record) => ( 
          <span>
            {console.log('record to cj = ',record)}
            {
              record.SingleMulti === 'Single' ? 
                <a href={`${ApiEndPoint}/em/s/${record.APIKey}/${record.key}`} target='_blank'>{`${ApiEndPoint}/em/s/${record.APIKey}/${record.key}`}</a>
              : record.pythonGen === 1 ? <a href={`${ApiEndPoint}/v2/${record.APIKey}/${record.key}`}  target='_blank'>{`${ApiEndPoint}/v2/${record.APIKey}/${record.key}`}</a> : 
                <a href={`${ApiEndPoint}/em/m/${record.APIKey}/${record.key}`} target='_blank'>{`${ApiEndPoint}/em/m/${record.APIKey}/${record.key}`}</a>
            }
          </span>
        ),
      },
      {
        title: 'Action', 
        dataIndex: 'action',
        render: (_, record) => (
            <Button onClick={() =>  handleViewFromDb(record)}>view</Button>
        ),
      },
      {
        title: 'Delete', 
        dataIndex: 'Delete',
        render: (_, record) => (
            <Button onClick={async () => {
              const confirmed = await modal.confirm(configConfirm);
              if(confirmed){
                handleDeleteInnerRow(record); 
              }
            }}><DeleteFilled /></Button>
        ),
      },
    ];
  

    useEffect(() => {
      getAll360Data(setData);  // Pass setData to the function
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
      };
  

    const handleOk = async () => {
        try {
            await form.validateFields();
            const response = await saveCompany(formData); 
            console.log('Form data submitted:', JSON.stringify(response));

            if (response.status) {
              
              setIsModalOpen(false);
              form.resetFields();
              setFormData({});
          
        

              getAll360Data(newData => {
                setData(newData);
              });
            } else {
              console.error('Form data submission failed:', response.error);
            }

          } catch (errorInfo) {
            console.error('Form validation failed:', errorInfo);
          }
      };


      const handleCancel = () => {
        form.resetFields(); 
        setFormData({});
        setIsModalOpen(false);
      };

      const handleDeleteInnerRow = (record) => {
       const a= delete360fromList(record.key);
       console.log('delete respons ',a);
        const updatedInnerTableData = innerTableData.filter(item => item.key !== record.key);
        setInnerTableData(updatedInnerTableData);
        //cjs
        if(record.SingleMulti =='Single' ){
          const updatedData = data.map(item =>
            item.key === record.key ? { ...item, SingleCount: item.SingleCount - 1 } : item
          );
          setData(updatedData);
        }else if (record.SingleMulti =='Single'){
          const updatedData = data.map(item =>
            item.key === record.key ? { ...item, MultiCount: item.MultiCount - 1 } : item
          );
          setData(updatedData);
        }

       
      };
      

    function handleAction(record) {
        // Implement your action logic here for the specific row
        console.log('Action clicked for row:', record);
      }

      function handleSingale360(record) {
        // Implement your action logic here for the specific row
        console.log('Action clicked for row:', record);
        setRecord(record)
        setIsModalAddOpen(true);
      }

      function handleMulti360(record){
        setRecord(record)
        setIsModalAddMultiOpen(true);
      }

      function open360(){
        navigate('/360img');
      }

    function showAddModal(){
       setIsModalAddOpen(true);
    } 
    
    const uploadStateCheck=(state,recodeVal,multiOrSingle)=>{
        if(state){
          if(multiOrSingle == 'single'){
            setIsModalAddOpen(false);
            const updatedData = data.map(item =>
              item.key === record.key ? { ...item, SingleCount: item.SingleCount + 1 } : item
            );
            setData(updatedData);
          }else{
            setIsModalAddMultiOpen(false);
            const updatedData = data.map(item =>
              item.key === record.key ? { ...item, MultiCount: item.MultiCount + 1 } : item
            );
              setData(updatedData);
          }
          
          handlePlusClick(recodeVal)
          openNotificationWithIcon('success');
        }
    }
  

    const handlePlusClick = async  (record) => {
      // Assuming `record.companyID` contains the relevant company ID
      //fetchInnerTableData(record.key);
      try {
        setLoading(true);
        const response = await  getInnerTableData(record.key);
        console.log(' my response ', response,'key= ',record.key);

        if (response) {
          setInnerTableData(response);
          setExpandedRowKey(record.key); 
        }
      } catch (error) {
        console.error('Error fetching inner table data:', error);
      } finally {
        setLoading(false);
      }

      // You can also use record as needed to further process the data
    };


    function addHandleCancel(){
      setIsModalAddOpen(false);
    }

    function addMultiHandleCancel(){
      setIsModalAddMultiOpen(false);
    }

    const handleViewFromDb = (recodes)=>{
      setView360fromDB(true)
      setInnerRecord(recodes)
    }
    const view360fromDHandleCancel =()=>{
      setView360fromDB(false)
    }
    
    return(
        <div className='w100 container '>
          {contextHolder} {contextHolderconfirm}
            <div className='addNew '>
                <strong  className='mr-10'>Add new Client</strong>
                <Button type="primary" shape="circle" icon={<PlusOutlined />} size={'large'} onClick={showModal} />
                <Button type="primary" className='test'  shape="circle" icon={<PlusOutlined />} size={'large'} onClick={open360} />
                <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <AddCompanyForm formData={formData} onInputChange={handleInputChange} form={form}/>
                </Modal>
                <Modal title={record.companyName} className='addSingleImage' open={isModalAddOpen}  onCancel={addHandleCancel} footer={null}>
                    <Add360Form  uploadState={uploadStateCheck} record={record}/>
                </Modal>
                <Modal title={record.companyName} className='addMultiImage'  open={isModalAddMultiOpen} onCancel={addMultiHandleCancel} footer={null} maskClosable={false}> 
                    {/* <Add360Form  uploadState={uploadStateCheck} record={record}/> */}
                    <AddMulti360Form uploadState={uploadStateCheck} record={record}/>
                </Modal>
                <Modal title={'View images'} className='addSingleImage' open={view360fromDB}  onCancel={view360fromDHandleCancel} footer={null}>
                    <View360FromDb  record={innerRecord}/>
                </Modal>

            </div>
            <div className='table-continor'>
            {/* <Table
                columns={columns}
                dataSource={data}
                bordered
                title={() => 'Header'}
                footer={() => 'Footer'}
            /> */}

             <Table
              columns={columns}
              dataSource={data}
              bordered
              title={() => ''}
              footer={() => 'Totle Clients '+data.length}
              expandable={{
                expandedRowRender: (record) => (
                  <Table
                    columns={innerColumns}
                    dataSource={innerTableData.map(innerRecord => ({ ...innerRecord, APIKey: record.APIKey }))}
                    pagination={false} // Disable pagination for inner table
                    loading={loading} 
                    className="nested-table" 
                  />
                ),
                
                onExpand: (expanded, record) => {
                 if (expanded && record.innerTableDataCount > 0) {
                      handlePlusClick(record);
                    } else {
                      setExpandedRowKey(null); // Collapse the row
                    }
                },
                expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
              }}
              rowClassName={(record) => {
                // Add a class name based on the expandability condition
                return record.innerTableDataCount > 0 ? 'expandable-row' : 'unexpandable-row';
              }}

            />
            </div>
 
        </div>
    )

}

export default ListingPage;