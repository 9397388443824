import React, { Fragment, useState } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {imageUpload} from '../services/dataSendGet'
import SinglePannellumViewer from '../component/pannellum/SinglePannellumViewer'


const Add360Form = ({uploadState, record}) => {

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [showSingaleView,setShowSingaleView] = useState(false);
  const [imageData ,setimageData]= useState([]);//server update and get state

  const [imageChange ,setimageChange]= useState([]);
  const [mouseMoveCoordinates, setMouseMoveCoordinates] = useState({ pitch: null, yaw: null });


  
  // const customRequest = async ({ file, onSuccess, onError }) => {
  //   try {
  //   //   const formData = new FormData();
  //   //   formData.append('file', file);
  //   //   formData.append('companyName', 'Alpine'); 
  //   //   formData.append('sceneName', );

  //     const response = imageUpload(file,'Alpine','firstScene');

  //     if (response.status === 200) {
  //       message.success('File uploaded successfully');
  //       onSuccess();
  //     } else {
  //       message.error('File upload failed');
  //       onError();
  //     }
  //   } catch (error) {
  //     console.error('File upload failed:', error);
  //     message.error('File upload failed');
  //     onError();
  //   }
  // };


  const customRequest=({ file, onSuccess, onError })=>{
      setFileList(file);
      try {
        // Simulate a successful upload
        setTimeout(() => {
          // Modify the status and response properties of the uploaded file
          file.status = 'done';
          file.response = 'success';
    
          // Call onSuccess to indicate a successful upload
          onSuccess();
    
          // You can also handle other upload logic here
        }, 2000); // Simulate a delay for demonstration purposes
      } catch (error) {
        console.error('File upload failed:', error);
        message.error('File upload failed');
        onError();
      }
  }


  const props = {
    customRequest,
    maxCount:1,
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        console.log(file, fileList);
        const localImageUrl = URL.createObjectURL(file.originFileObj);
        setimageChange(localImageUrl)
      }
      setFileList([...fileList]);
    },
    
  };




  const handleSubmit =  (values) => {
    try {
      
      const formData = new FormData();

      console.log('form values:', values);
    formData.append('id',  record.key);  
    formData.append('file', values.file.file.originFileObj);
    formData.append('companyName', record.companyName);
    formData.append('sceneName', values.sceneName);
    formData.append('SingleMulti', 'Single')
  
      console.log('form values:', values.file.file);
      console.log('form data:', formData);

      const response =  imageUpload(formData);
      
      response.then(responseData => {
        console.log('file response', responseData)
        const message = responseData.data.message;
        const state =  responseData.data.state;

          if(state){
           const filePath = responseData.data.filePath;
           setimageData({
              folder:responseData.data.folder,
              fullfileName :responseData.data.fullfileName,
              fileName :responseData.data.fileName,
              imagePath:'/'+responseData.data.folder+'/'+responseData.data.fullfileName,
              fileExtension : responseData.data.fileExtension,
              basePath:filePath,
           });
            setShowSingaleView(true);
            uploadState(true,record,'single')
          } else{
            uploadState(false,record,'single')
          }

      });
      console.log('response  ',response);
  
      // Set the status and response properties of the uploaded file
      const uploadedFile = values.file.fileList[0];
      uploadedFile.status = 'done';
      uploadedFile.response = 'success';
    } catch (error) {
      console.error('File upload failed:', error);
      message.error('File upload failed');
    }
  };
  
  const handleMouseMove = (pitch, yaw) => {
    setMouseMoveCoordinates({ pitch, yaw });
  };

  return (
    <Fragment>
      

      {(imageChange.length !== 0)  && (
        <div>
        <SinglePannellumViewer image={imageChange} imageFromServer={false} onCenterCoordinates={handleMouseMove}/>
        <p>Mouse Move Pitch: {mouseMoveCoordinates.pitch}</p>
        <p>Mouse Move Yaw: {mouseMoveCoordinates.yaw}</p>
      </div>
      )}  
      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="vertical"
        initialValues={{
          size: 'default',
        }}
        size={'default'}
        style={{ maxWidth: 600 }}
      >
       
        {/* <Form.Item
          label="Company Name"
          name="companyName"
          rules={[
            {
              required: true,
              message: 'Please enter the company name',
            },
          ]}
        >
          <Input name="companyName" />
        </Form.Item> */}

        <Form.Item
          label="Scene Name"
          name="sceneName"
          rules={[
            {
              required: true,
              message: 'Please enter the scene name',
            },
          ]}
        >
          <Input name="sceneName" />
        </Form.Item>
        <Form.Item 
        label="Image Upload"
        name="file"
         rules={[
          {
            required: true,
            message: 'Please Upload Image',
          },
        ]}
        >
          {/* <Upload  {...props}></Upload> */}
          <Upload {...props} name="file">
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
            <Button type='primary' htmlType='submit' >Submit</Button>
        </Form.Item>
      </Form>
      
{console.log('me ',imageData)}

      { /* {showSingaleView && (
        <div>
          <SinglePannellumViewer image={imageData} imageFromServer={true}/>
        </div>
      )} */}
    </Fragment>
  );
};

export default Add360Form;
