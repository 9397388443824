import axios from 'axios';
import ApiEndPoint from '../config'; 

const config = {
  headers: {
    'X-API-Key': 'cjsMks',
    'Content-Type': 'application/json',
  },
  withCredentials: false,
};

const config2 ={
  headers: {
    'X-API-Key': 'cjsMks',
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: false,
}


export const saveCompany = async (formData) => {
  try {
  const response =await  axios.post(ApiEndPoint+'/savecompany', {
    companyName:formData.companyName,
    domain:formData.domainName,
  }, config);
    console.log('Response:', response.data);
    return response.data;
 
}catch(error) {
  console.error('Error:', error);
  throw new Error('Error saving company data');
}
 
}


export const getAll360Data =(setData)=>{
  
  axios.get(ApiEndPoint+'/getData', config).then(response => {
    console.log('Response:', response.data);
    setData(response.data); 
  })
  .catch(error => {
    console.error('Error:', error);
  });
 

}

export const getImagefromServer = (imagePath) => {
  return new Promise((resolve, reject) => {
    axios
      .get(ApiEndPoint + imagePath, { ...config, responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'image/png' });
        const objectURL = URL.createObjectURL(blob);
        resolve(objectURL);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const imageUpload =async (formData)=>{
  const response =await  axios.post(ApiEndPoint+'/imageUp', formData, config2);

  return response;
}


export const multiImageViewerDataSend = async (formData)=>{
  console.log('befor send ',formData);
  const response =await  axios.post(ApiEndPoint+'/multiImageView', formData, config2);
  return response;
}

//python gen multi res
export const multiImageViewerDataSendPygen = async (formData)=>{
  console.log('befor send ',formData);
  const response =await  axios.post(ApiEndPoint+'/multiImageViewPygen', formData, config);
  return response;
}


export const getInnerTableData = (companyID) =>{
  return axios.get(ApiEndPoint+`/getinnerData/${companyID}`, config).then(response => {
    console.log('Response db: cj', response.data);
    return response.data; 
  })
  .catch(error => {
    console.error('Error:', error);
  });
}


export const delete360fromList =(id)=>{
  return axios.get(ApiEndPoint+`/delete360/${id}`, config).then(response => {
    console.log('Response db:', response);
    return response.message; 
  })
  .catch(error => {
    console.error('Error:', error);
  });
}

export  {ApiEndPoint};