import React from 'react';
import { Fragment } from 'react';
import SinglePannellumViewer from './pannellum/SinglePannellumViewer';




const View360FromDb = (record) =>{


    const urlCreate=()=>{
        console.log('recode val ',record.record.imageName)
        const relativePath =record.record.imageName;
        const absolutePath = '/' + relativePath.replace(/\\/g, '/');
        console.log(' path cj-   ',absolutePath);
        

        return absolutePath;

    }

    

    return( 
        <Fragment>
            <SinglePannellumViewer image={{ imagePath: urlCreate() }} imageFromServer={true}/>
        </Fragment>
    )

}

export default View360FromDb