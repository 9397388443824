import React,{useState} from 'react';
import {Avatar,Button} from 'antd';
import { UserOutlined, PoweroffOutlined} from '@ant-design/icons';
import { useLogout } from '../services/authService';
import logo from './img/logo.png';


const AuthHeader =()=>{
    const [loadings, setLoadings] = useState([]);
    const logout = useLogout(); 

    const enterLoading = (index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
        setTimeout(() => {
            setLoadings((prevLoadings) => {
                const newLoadings = [...prevLoadings];
                newLoadings[index] = false;
                return newLoadings;
            });
        }, 6000);

        logout();
    };

    
    return(
        <div className='header '>
            <div className='container flex flexRow h70 aic jcsb'>
                <div className='brand'>
                    <img src={logo} alt='SolidMotion' style={{width:'175px'}}></img>
                </div>
                {/* <div className='companyName'></div> */}
                <div className='flex g-10'>
                    <div className='userInfo'>
                        <Avatar icon={<UserOutlined />} />
                    </div>
                    <div className='logout'>
                    <Button 
                        type="primary"
                        icon={<PoweroffOutlined />} 
                        loading={loadings[0]} 
                        onClick={() => enterLoading(0)}
                    >
                        LogOut!
                    </Button>
                    </div> 
                </div>
                
            </div>
        </div>
    )

}

export default AuthHeader;