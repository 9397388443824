import React from 'react';
import './App.css';
import './scss/main.scss';
import PrivateRoute from './component/PrivateRoute';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import ImageViewer360 from './component/360ImageViewer';
import LoginForm from './component/loginForm';
import ListingPage from './pages/listing-page';
import AuthHeader from './component/Authheader';
import EmbendLinkCreator from './component/EmbendLinkCreater';
import  PannellumViewer  from './component/pannellum/PannellumViewer';
import {ConfigProvider} from 'antd'


const App = () => {

     return (
      <ConfigProvider
      theme={{
        token: {

        },
      }}
    >
      <Router>
      <Routes>
      <Route
          path="/"
          element={
            <PrivateRoute>
              {/* <ImageViewer360 /> */}
              <AuthHeader/>
              <ListingPage/>
            </PrivateRoute>
          }
        /> 
        <Route path='/360img' element={< PannellumViewer/>}/> 
        <Route path="/login" element={<LoginForm />} />
        <Route path="/em/s/:apiKey/:scene" element={<EmbendLinkCreator />} />
      </Routes>
    </Router>
    </ConfigProvider>
    );
  
};

export default App;